var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[(!_vm.isDeleteOption)?_c('div',[_c('v-card-title',[_c('v-btn',{staticClass:"ml-n3 mr-2",attrs:{"small":"","icon":"","color":"primary"},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('h2',{staticClass:"font-weight-regular"},[_vm._v(" Edit BOM Item ")])],1),_c('v-divider',{staticClass:"ml-4 mr-5"}),_c('v-card-text',[_c('div',[_c('v-data-table',{staticClass:"elevation-0 mt-5",style:(`height: ${_vm.height}px`),attrs:{"id":"virtual-scroll-table","headers":_vm.headers,"items":_vm.reservesFiltered,"mobile-breakpoint":0,"loading":_vm.loading,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"mt-0 mx-0 pt-4 pb-0 pb-4",staticStyle:{"background-color":"#eeeeee"},attrs:{"no-gutter":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',{staticClass:"mx-4 my-n3"},[_vm._v("RESERVED STOCK")])])],1)]},proxy:true},{key:`item.name`,fn:function({ item }){return [_c('p',{staticClass:"my-0"},[_vm._v(" "+_vm._s(`${item.material} ${item.short_material_text}`)+" ")])]}},{key:`item.quantity`,fn:function({ item }){return [(_vm.loading)?_c('div',[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":"24"}})],1):_c('div',[_c('p',{staticClass:"my-0"},[_vm._v(_vm._s(item.free_use))])])]}},{key:`item.qty`,fn:function({ item }){return [_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"type":"number","rules":[
                                    () =>
                                        _vm.rules.maxValue(
                                            item.bomQuantity,
                                            item
                                        ),

                                    () => _vm.rules.checkRequired(item),
                                ],"required":""},model:{value:(item.bomQuantity),callback:function ($$v) {_vm.$set(item, "bomQuantity", $$v)},expression:"item.bomQuantity"}})],1)]}},{key:`item.notes`,fn:function({ item }){return [_c('v-text-field',{model:{value:(item.notes),callback:function ($$v) {_vm.$set(item, "notes", $$v)},expression:"item.notes"}})]}}],null,true)})],1)]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mt-n2 mb-3 mx-2",attrs:{"loading":_vm.loading,"color":"primary","rounded":"","disabled":!_vm.valid},on:{"click":_vm.saveBOM}},[_vm._v(" "+_vm._s('EDIT BILL OF MATERIAL ITEM')+" ")])],1)],1):_c('div',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Delete BOM Item")]),_c('v-card-text',[_vm._v(" Are you sure you want to delete this BOM Item? ")]),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"secondary"},on:{"click":_vm.close}},[_vm._v(" Close ")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"error","loading":_vm.loading},on:{"click":_vm.deleteBomItem}},[_vm._v(" Delete ")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }